<template>
  <FocusTrap>
    <div class="card">

    <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

      <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Extrusion Delivery List</h5>

      <div class="header-elements">
        <form action="#">
          <div class="form-group-feedback form-group-feedback-right">
            <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
            <div class="form-control-feedback">
              <i class="icon-search4 font-size-base text-muted"></i>
            </div>
          </div>
        </form>

      </div>
    </div>


    <div class="card-body" style="padding: 0px;">
      <div class="table-responsive">
      <table id="mytable"
             class="table  table-hover  table-bordered table-condensed table-columned"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-detail-view="true"
             data-detail-formatter="extrusionDeliveryDetailFormatter"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-formatter="runningFormatter" data-width="75" >S.No</th>
          <th data-field="doc_no" data-width="80" data-sortable="true">Doc No</th>
          <th data-field="doc_date" data-width="110" data-formatter="dateFormatter"  data-sortable="true">Date</th>
          <th data-field="shift.name" data-sortable="true">Shift</th>
          <th data-field="machine.name" data-sortable="true">Machine</th>
          <th data-field="employee.name" data-sortable="true">Operator</th>
          <!--<th data-field="yarn_wgt" data-formatter="weightFormat" data-width="125" data-sortable="true" data-align="right">Weight</th>-->
          <th data-field="remarks" data-sortable="true">Remarks</th>

        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: blue"></i>Refresh</a>
        <div class="dropdown-divider"></div>
<!--        <a href="#" class="dropdown-item" ><i class="icon-puzzle"></i>Breakup</a>-->
        <a href="#" class="dropdown-item" ><i class="icon-pencil7" style="color: #2d7330"></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" ><i class="icon-blocked" style="color: orangered"></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->


<!--    <vmodal name="extrusion_modal_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="90%" height="82%" @click="showModal" @before-open="beforeOpen" @before-close="beforeClose" >-->
<!--      <ExtrusionForm v-bind:myvoucher="voucher" ></ExtrusionForm>-->
<!--    </vmodal>-->

<!--    <focus-trap :active="false" ref="focusTrap">-->
    <focus-trap v-model="isActive">
      <div class="modal" id="extrusion_modal_window" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content" >

          <!-- Modal body -->
          <div class="modal-body" style="padding: 0px">
            <ExtrusionForm  v-if="voucher.id > 1" v-bind:myvoucher="voucher" v-on:extrusiondelivery_window_closed="closeModal"  v-on:extrusion_receipt_saved="loadData" ></ExtrusionForm>
          </div>

        </div>
      </div>
    </div>
    </focus-trap>

    <vmodal name="brkup_modal_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="90%" :height="545" @click="showModal" @before-open="beforeOpen" @before-close="beforeClose" >
      <BreakupForm v-bind:myvoucher="voucher" v-bind:myqty="voucher.yarn_qty" ></BreakupForm>
    </vmodal>

    <!-- End of Modal Window -->

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
          <i class="fab-icon-open icon-filter3"></i>
          <i class="fab-icon-close icon-filter4"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->


    <FocusTrap>
      <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="575" height="250" @opened="afterfilterwindow_visible">
        <form>
          <div class="card">
            <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
              <h3 class="card-title">Filter</h3>
              <div class="header-elements">
                <div class="list-icons">
                  <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                </div>
              </div>
            </div>

            <div class="card-body">

              <p/>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">From:</label>
                <div class="col-md-3">
                  <input id= "txtfrom" ref= "txtfrom" type="date" class="form-control"  v-model="from_date" :max="upto_date" autofocus />
                </div>

                <label class="col-md-3 col-form-label">Upto</label>
                <div class="col-md-3">
                  <input type="date" class="form-control"  v-model="upto_date" :min="from_date" />
                </div>
              </div>


              <div class="form-group row">
                <label class="col-md-3 col-form-label">Machine:</label>
                <div class="col-md-9">
                  <select id="cmbMachine" class="form-control" v-model="machine_id" >
                    <option value="0" selected>All</option>
                    <option v-for="camp in machines" v-bind:value="camp.id">
                      {{ camp.name }}
                    </option>
                  </select>
                </div>
              </div>


              <div class="text-right">
                <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
              </div>

            </div>
          </div>
        </form>
      </vmodal>
    </FocusTrap>


  </div>
  </FocusTrap>
</template>

<script>
  import ExtrusionForm from './ExtrusionDeliveryForm'
  import BreakupForm from './ExtrusionBreakupForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'ExtrusionDeliveryView',
    store,
    components: {
      ExtrusionForm,
      BreakupForm,
      FabButton,
      moment
    },
    data () {
      return {
        isActive: false,
        voucher: JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"trn_id":1,"remarks":"","company":{"id":1,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""},"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":1,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":true,"stentering":true,"warping":false},"list":[]}'),
        mytable: {},
        from_date: moment().add(-7, 'days').format('YYYY-MM-DD'),
        upto_date: moment().format('YYYY-MM-DD'),
        machine_id : 0,
        machines : [],
      }
    },
    created () {

    },
    mounted () {
      const self = this;

      self.$data.mytable = $('#mytable');
      self.$data.mytable.bootstrapTable();

      $('[data-toggle="popover"]').popover();
      $('#mytable').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {

          let id = $(row.children('*')[1]).text();

          if ($(e.target).text() === "Refresh") {
            self.loadData();
          } else if($(e.target).text() === 'Breakup') {
            self.showBreakUpModal(id);
          } else if($(e.target).text() === 'Modify'){
            self.modifyDocument(id);
          }else if($(e.target).text() == 'Remove'){
            self.removeDocument(id);
          }

        }
      });

      // $('.select-search').select2();

      $('#txtsearch').keyup(function () {
        let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#mytable>tbody>tr').show().filter(function () {
          let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });


      $('#extrusion_modal_window').on('shown.bs.modal', function () {
        self.$data.isActive = true;
        $('#txtname').trigger('focus');
      });

      $('#extrusion_modal_window').on('hide.bs.modal', function () {
        self.$data.isActive = false;
        self.clear();
      });

      $('[data-action=remove]').click(function (e) {
        $('#extrusion_modal_window').modal('hide');
      });

      self.loadMachines();
      self.$modal.show('filter-window');

      $('#filter-window').on('shown.bs.modal', function () {
        $('#txtfrom').trigger('focus');
      });

      $('#extrusion_modal_window').modal('show');

    },
    methods: {
      afterfilterwindow_visible(){
        $('#txtfrom').focus();
      },
      showFilterWindow(){
        this.$modal.show('filter-window');
      },
      closeModal() {
        $('#extrusion_modal_window').modal('hide');
        this.$data.isActive = false;
      },
      showModal () {
        $('#extrusion_modal_window').modal('show');
        this.$data.isActive = true;
      },
      beforeClose(){

      },
      beforeOpen(){

      },
      showBreakUpModal(id){
        const self = this;

        if(id.length !== 24) {
          return;
        }

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };


        self.$data.voucher = {};
        fetch(`${process.env.VUE_APP_ROOT_API}api/extrusion/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok ) {
            if(resp.data != null){
              self.$data.voucher = resp.data;
              self.$data.voucher.date = moment(self.$data.voucher.date).format('YYYY-MM-DD');

              self.$modal.show('brkup_modal_window');
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error", timer:1500} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text:  err.toString() , type:  "error", timer:1500} );
        });

      },
      clear(){
        this.voucher = JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"trn_id":1,"remarks":"","company":{"id":1,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""},"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":1,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":true,"stentering":true,"warping":false},"list":[{"id":0,"master_id":0,"item_id":0,"desc":"","weight":0,"item":{"id":1,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0}}]}');
      },
      modifyDocument (id) {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.voucher = {"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"trn_id":1,"remarks":"","company":{"id":1,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":""},"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":1,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":true,"stentering":true,"warping":false},"list":[{"id":0,"master_id":0,"item_id":0,"desc":"","weight":0,"item":{"id":1,"status":"Active","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0}}]};
        fetch(`${process.env.VUE_APP_ROOT_API}api/extrusion/delivery/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {

          if(resp.ok ) {

              self.$data.voucher = resp.data;

              self.$data.voucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');

              self.$data.isActive = true;
              $('#extrusion_modal_window').modal('show');

          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error", timer:1500} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text:  err.toString() , type:  "error", timer:1500} );
        });
      },
      removeDocument (id) {
        const self = this;

        self.$data.voucher.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.voucher)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete this!'
        }).then((result) => {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}api/extrusion/delivery`,requestOptions).then(userService.handleResponse).then(function (resp) {
              $('#extrusion_modal_window').modal('hide');
              self.loadData();
              swal({ title:'Deleted!', text:'Your request has been processed', type:'success', timer:1500});
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" , timer:1500});
            });
          }
        });

      },
      loadData () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $('#extrusion_modal_window').modal('hide');

        $(self.$data.mytable).block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$modal.hide('filter-window');
        self.$data.mytable.bootstrapTable('load',[]);

        let filters = `from=${self.from_date}&upto=${self.upto_date}&machine_id=${self.machine_id}`;

        fetch(`${process.env.VUE_APP_ROOT_API}api/extrusion/deliveries?${filters}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if(_.isArray(resp.data)){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error", timer:1500} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error", timer:1500} );
        }).finally(function () {
          $(self.$data.mytable).unblock();
        });
      },
      loadMachines(){
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.machines = [];

        fetch(`${process.env.VUE_APP_ROOT_API}api/machines/type/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if(_.isArray(resp.data)){
              self.$data.machines =  resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      },
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
